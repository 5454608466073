export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const DESTROY_SESSION = 'DESTROY_SESSION'

export const REQUEST_OTP = 'REQUEST_OTP'
export const SUBMIT_OTP = 'SUBMIT_OTP'

export const COMMON_MAS_VALUE = 'COMMON_MAS_VALUE'
export const MAS_CMP_CUST_AGREE = 'MAS_CMP_CUST_AGREE'
export const MAS_SUB_REQ = 'MAS_SUB_REQ'

export const CREATE_STREAM = 'CREATE_STREAM'
export const FETCH_STREAMS = 'FETCH_STREAMS'
export const FETCH_STREAM = 'FETCH_STREAM'
export const DELETE_STREAM = 'DELETE_STREAM'
export const EDIT_STREAM = 'EDIT_STREAM'

export const CREATE_CONSENT = 'CREATE_CONSENT'
export const FETCH_CONSENTS = 'FETCH_CONSENTS'
export const FETCH_CONSENT = 'FETCH_CONSENT'
export const DELETE_CONSENT = 'DELETE_CONSENT'
export const EDIT_CONSENT = 'EDIT_CONSENT'
export const GET_CONSENT = 'GET_CONSENT'
export const SAVE_CONSENT = 'SAVE_CONSENT'

export const CREATE_CONSENT_CUST = 'CREATE_CONSENT_CUST'
export const FETCH_CONSENT_CUST_LIST = 'FETCH_CONSENT_CUST_LIST'
export const FETCH_CONSENT_CUST = 'FETCH_CONSENT_CUST'
export const DELETE_CONSENT_CUST = 'DELETE_CONSENT_CUST'
export const EDIT_CONSENT_CUST = 'EDIT_CONSENT_CUST'

export const CREATE_REQUEST_CUST = 'CREATE_REQUEST_CUST'
export const FETCH_REQUEST_CUST = 'FETCH_REQUEST_CUST'
export const EDIT_REQUEST_CUST = 'EDIT_REQUEST_CUST'
export const SAVE_REQUEST_CUST = 'SAVE_REQUEST_CUST'

export const SEARCH_CMP_CLIENT = 'SEARCH_CMP_CLIENT'

export const MIT_CLIENTS = 'MIT_CLIENTS'
export const MIT_CLIENTS_SEARCH = 'MIT_CLIENTS_SEARCH'
export const MIT_CLIENTS_ID = 'MIT_CLIENTS_ID'
export const MIT_EDIT_CLIENT = 'MIT_EDIT_CLIENT'

export const GET_TAXREFUND = 'GET_TAXREFUND'
export const SAVE_TAXREFUND = 'SAVE_TAXREFUND'

export const GET_SUIT_BYID = 'GET_SUIT_BYID'
export const SAVE_SUIT = 'SAVE_SUIT'
// export const SUIT_QUESTION = 'SUIT_QUESTION'

export const GET_PF_REPORTS = 'GET_PF_REPORTS'
export const DOWNLOAD_PF_REPORT = 'DOWNLOAD_PF_REPORT'
export const SHOW_PORTFOLIO_APP = 'SHOW_PORTFOLIO_APP'
