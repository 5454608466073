// import { createStore } from 'redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import reducers from './components/mpam/reducers'

const initialState = {
  sidebarShow: true,
  todos: [{ id: 123, text: 'hello', completed: false }],
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

// const mpam_store = createStore(reducers)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function loadFromLocalStorage() {
  try {
      const serialisedState = localStorage.getItem("persistantState");
      if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
      } catch (e) {
      console.warn(e);
      return undefined;
  }
}
function saveToLocalStorage(state) {
  try {
      const serialisedState = JSON.stringify(state);
      localStorage.setItem("persistantState", serialisedState);
  } catch (e) {
      console.warn(e);
  }
}

const enhancer = composeEnhancers(
  applyMiddleware(reduxThunk),
  // other store enhancers if any
)
// const store = createStore(coreui_store, reducers, enhancer)
// const store = createStore(enhancer)
const store = createStore(reducers, loadFromLocalStorage(),enhancer)
// const store = createStore(changeState, enhancer)
store.subscribe(() => saveToLocalStorage(store.getState()));

export default store
