import { SIGN_IN, SIGN_OUT, REQUEST_OTP, SUBMIT_OTP } from '../actions/type'

const INIT_STATE = {
  // isSignedIn: null,
  // userId: null,
  // email: null,
  // compCode: null,
  // custName: null,
  // cardId: null,
  // mobile: null,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      console.log(`*** ${action.type} >> ${action.payload}`)
      return {
        ...state,
        isSignedIn: true,
        userId: action.payload.userId,
        email: action.payload.email,
        compCode: action.payload.compCode,
        custName: action.payload.custName,
        cardId: action.payload.cardId,
        mobile: action.payload.mobile,
        pathAction: action.payload.pathAction,
        VerifyDT:action.payload.VerifyDT,
      }
    case SIGN_OUT:
      return { ...state, 
        isSignedIn: false, 
        userId: null, 
        email: null,
        compCode: null,
        custName: null,
        cardId: null,
        mobile: null,
        pathAction: null,
      }
    case REQUEST_OTP:
      return { ...state,
        reqOtpSuccess:action.payload.reqOtpSuccess,
        code:action.payload.code || null,
        message:action.payload.message || null,
        // otp:action.payload.otp || null,
        otpref:action.payload.otpref || null,
        otpAlive:action.payload.otpAlive || null,

        cid:action.payload.cid || null,
        mobile:action.payload.mobile || null,
        capchaVerify:action.payload.capchaVerify || null,
      }
    case SUBMIT_OTP:
        return { ...state,
          submitOtpSuccess: action.payload.submitOtpSuccess,
          isSignedIn: action.payload.isSignedIn,
          code: action.payload.code || null,
          message: action.payload.message || null,
          userId:action.payload.userId || null,
          Name:action.payload.Name || null,
          otpFailed:action.payload.otpFailed || null,
        }
    
    default:
      return state
  }
}
