import _ from 'lodash'
import { CREATE_REQUEST_CUST, FETCH_REQUEST_CUST, EDIT_REQUEST_CUST,SAVE_REQUEST_CUST } from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_REQUEST_CUST:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_REQUEST_CUST:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_REQUEST_CUST:
      return { ...state, [action.payload.id]: action.payload }
    case SAVE_REQUEST_CUST:
      //return { ...state, [action.payload]: action.payload }
      //return { ...state, ['resRequest']: action.payload }
      return { ...state,
        save_request_process: action.payload.save_request_process || false,
        save_request_status: action.payload.save_request_status || null,
        save_request_message: action.payload.save_request_message || null,
      }

    default:
      return state
  }
}
