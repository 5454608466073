import React, { Component } from 'react'
import { HashRouter, Router, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import createBrowserHistory from './components/mpam/history'
require('dotenv').config()


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
//const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const CustPortal = React.lazy(() => import('./components/mpam/home/custPortal'))
const Test = React.lazy(() => import('./components/mpam/home/test'))
// const Test2 = React.lazy(() => import('./components/mpam/home/test2'))
// const Test3 = React.lazy(() => import('./components/mpam/home/test3'))


const Login = React.lazy(() => import('./views/pages/login/Login'))
const VerifyCID = React.lazy(() => import('./components/mpam/login/VerifyCID'))
const PrivacyPolicy = React.lazy(() => import('./components/mpam/login/PrivacyPolicy'))
const CustCompany = React.lazy(() => import('./components/mpam/login/custCompany'))

const CustConsent = React.lazy(() => import('./components/mpam/consent/custConsent'))
const CustRequestNew = React.lazy(() => import('./components/mpam/consent/custRequestNew'))
const CustConsentHistory = React.lazy(() => import('./components/mpam/consent/custConsentHistory'))
const CustPortalConsent = React.lazy(() => import('./components/mpam/consent/custPortalConsent'))
const InvestPage = React.lazy(() => import('./components/mpam/investor/investPage'))


const CustRequestLBDU = React.lazy(() => import('./components/mpam/register/custRequestLBDU'))
const CustPortalLBDU = React.lazy(() => import('./components/mpam/register/custPortalLBDU'))
const CustLBDUHistory = React.lazy(() => import('./components/mpam/register/custLBDUHistory'))
const Register = React.lazy(() => import('./views/pages/register/Register'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const CustTaxRefund = React.lazy(() => import('./components/mpam/taxrefund/custTaxRefund'))
const CustSuit = React.lazy(() => import('./components/mpam/suit/custSuit'))
const MailTemplate = React.lazy(() => import('./components/mpam/test/mailTemplate'))

const CustTransFer = React.lazy(() => import('./components/mpam/transfer/custRequestTransFer'))

class App extends Component {
  render() {
    return (
      <Router history={createBrowserHistory}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/custPortal"///C_custPortal/:compCode
              name="Cust Portal (PDPA)"
              render={(props) => <CustPortal {...props} />}
            />
            <Route
              exact
              path="/verifyCID/:compCode"
              name="verify CID"
              render={(props) => <VerifyCID {...props} />}
            />
            <Route
              exact
              path="/verifyCID/:compCode/:pathAction"
              name="verify CID"
              render={(props) => <VerifyCID {...props} />}
            />
            <Route
              exact
              path="/privacyPolicy"
              name="Privacy Policy"
              render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
              exact
              path="/custConsent"
              name="Consent"
              render={(props) => <CustConsent {...props} />}
            />
            <Route
              exact
              path="/custRequestNew/:id"
              name="Request Consent"
              render={(props) => <CustRequestNew {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/custHistoryConsent"
              name="History Consent"
              render={(props) => <CustConsentHistory {...props} />}
            />
            <Route
              exact
              path="/investment"
              name="History Consent"
              render={(props) => <InvestPage {...props} />}
            />
            <Route
              exact
              path="/test"
              name="Test"
              render={(props) => <Test {...props} />}
            />
            {/* <Route
              exact
              path="/test2"
              name="Test2"
              render={(props) => <Test2 {...props} />}
            />
            <Route
              exact
              path="/test3"
              name="Test3"
              render={(props) => <Test3 {...props} />}
            /> */}
            <Route
              exact
              path="/custCompany"
              name="Cust Company"
              render={(props) => <CustCompany {...props} />}
            />
            <Route
              exact
              path="/custRequestLBDU/:id"
              name="Cust Request LBDU"
              render={(props) => <CustRequestLBDU {...props} />}
            />
            <Route
              exact
              path="/custPortalConsent"
              name="Cust Portal Consent"
              render={(props) => <CustPortalConsent {...props} />}
            />
            <Route
              exact
              path="/custPortalLBDU"
              name="Cust Portal LBDU"
              render={(props) => <CustPortalLBDU {...props} />}
            />
            <Route
              exact
              path="/custHistoryConsentLBDU"
              name="History LBDU"
              render={(props) => <CustLBDUHistory {...props} />}
            />
            <Route
              exact
              path="/custTaxRefund"
              name="Tax Refund"
              render={(props) => <CustTaxRefund {...props} />}
            />
            <Route
              exact
              path="/custSuit"
              name="Suitability "
              render={(props) => <CustSuit {...props} />}
            />
            <Route
              exact
              path="/testMail"
              name="Test"
              render={(props) => <MailTemplate {...props} />}
            />
            <Route
              exact
              path="/custTransFer"
              name="TransFer"
              render={(props) => <CustTransFer {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <CustCompany {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
