import _ from 'lodash'
import { COMMON_MAS_VALUE, MAS_CMP_CUST_AGREE, MAS_SUB_REQ,SHOW_PORTFOLIO_APP } from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case COMMON_MAS_VALUE:
      return { ...state, ..._.mapKeys(action.payload, 'value') }
    case MAS_CMP_CUST_AGREE:
      return { ...state, [1]: action.payload }
    case MAS_SUB_REQ:
      return { ...state, ['request']:action.payload }
    case SHOW_PORTFOLIO_APP:
      return { ...state, ['showPortfolioApp']: action.payload }
    default:
      return state
  }
}
