import _ from 'lodash'
import {
  FETCH_CONSENTS,
  FETCH_CONSENT,
  CREATE_CONSENT,
  EDIT_CONSENT,
  DELETE_CONSENT,
  GET_CONSENT,
  SAVE_CONSENT,
} from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONSENTS:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    case FETCH_CONSENT:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_CONSENT:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_CONSENT:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_CONSENT:
      return _.omit(state, action.payload)
    case GET_CONSENT:
      return { ...state, ['objCosents']: action.payload }
    case SAVE_CONSENT:
      //return _.omit(state, action.payload)
      //return { ...state, ['resConsents']: action.payload }

      return { ...state,
        save_consent_process: action.payload.save_consent_process || false,
        save_consent_status: action.payload.save_consent_status || null,
        save_consent_message: action.payload.save_consent_message || null,
      }

    default:
      return state
  }
}
