import _ from 'lodash'
import { MIT_CLIENTS, MIT_CLIENTS_ID, MIT_EDIT_CLIENT, MIT_CLIENTS_SEARCH } from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case MIT_CLIENTS:
      return {
        items: _.mapKeys(action.payload, 'id'),
        filteredItems: _.mapKeys(action.payload, 'id'),
      }
    case MIT_CLIENTS_SEARCH:
      return {
        ...state,
        name: action.payload.name,
        filteredItems: action.payload.items,
      }
    case MIT_CLIENTS_ID:
      return { ...state, [action.payload.id]: action.payload }
    case MIT_EDIT_CLIENT:
      return { ...state, [action.payload.id]: action.payload }
    default:
      return state
  }
}
