import _ from 'lodash'
import {
  GET_PF_REPORTS,
  DOWNLOAD_PF_REPORT,

} from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PF_REPORTS:
      return { ...state, ['investorPFreports']: action.payload }
    case DOWNLOAD_PF_REPORT:
      return { ...state, ['investorDownloadPFreport']: action.payload }
    default:
      return state
  }
}
