import _ from 'lodash'
import {
  FETCH_CONSENT_CUST_LIST,
  FETCH_CONSENT_CUST,
  CREATE_CONSENT_CUST,
  EDIT_CONSENT_CUST,
  DELETE_CONSENT_CUST,
  SEARCH_CMP_CLIENT,
} from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONSENT_CUST_LIST:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    case FETCH_CONSENT_CUST:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_CONSENT_CUST:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_CONSENT_CUST:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_CONSENT_CUST:
      return _.omit(state, action.payload)
    case SEARCH_CMP_CLIENT:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    default:
      return state
  }
}
