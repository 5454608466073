import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import authReducer from './authReducer'
import streamReducer from './streamReducer'
import consentReducer from './consentReducer'
import consentCustReducer from './consentCustReducer'
import requestCustReducer from './requestCustReducer'
import masterValueReducer from './masterValueReducer'
import mitClientReducer from './mitClientReducer'
import { DESTROY_SESSION} from '../actions/type'
import taxRefundReducer from './taxRefundReducer'
import suitReducer from './suitReducer'
import investordReducer from './investorReducer'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}


const appReducer = combineReducers({
  auth: authReducer,
  masterValue: masterValueReducer,
  mitClient: mitClientReducer,
  form: formReducer,
  streams: streamReducer,
  consents: consentReducer,
  consentCust: consentCustReducer,
  requestCust: requestCustReducer,
  sidebar: changeState,
  taxRefund: taxRefundReducer,
  suit: suitReducer,
  investor:investordReducer,
})

const rootReducer = (state, action) => {   
  if(action.type === DESTROY_SESSION)
     state = undefined;
  
  return appReducer(state, action);
}

export default rootReducer;
