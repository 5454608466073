import _ from 'lodash'
import {
    GET_TAXREFUND,
    SAVE_TAXREFUND,
} from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_TAXREFUND:
      return { ...state, ['objTaxRefund']: action.payload }
    case SAVE_TAXREFUND:
      //return _.omit(state, action.payload)
      //return { ...state, ['resConsents']: action.payload }
      return { ...state,
        save_taxrefund_process: action.payload.save_taxrefund_process || false,
        save_taxrefund_status: action.payload.save_taxrefund_status || null,
        save_taxrefund_message: action.payload.save_taxrefund_message || null,
      }

    default:
      return state
  }
}
