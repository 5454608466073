import _ from 'lodash'
import {
  GET_SUIT_BYID,
  SAVE_SUIT,
} from '../actions/type'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SUIT_BYID:
      return { ...state, ['objSuit']: action.payload }
    case SAVE_SUIT:
      return { ...state, ['objSuit']: action.payload }
    // case SUIT_QUESTION:
    //   return { ...state, ['objSuit']: action.payload }
    default:
      return state
  }

}
